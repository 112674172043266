<script lang="ts" setup>
const { t } = useT();

const openCashWithSwitchingScreen = () => {
	dispatchGAEvent({
		event: "verification",
		location: "header",
		step: "info",
		type: "duplicate_account"
	});
	window?.$cash?.$router?.push?.("/cash/deposit/");
};
</script>
<template>
	<AText
		class="topline text-cannes"
		as="div"
		variant="toledo"
		:modifiers="['bold']"
		@click="openCashWithSwitchingScreen"
	>
		<NuxtIcon name="20/lock" filled />
		<i18n-t keypath="Is this your second account? {key1} Please use the {key2}" tag="p">
			<template #key1>
				<br class="hide-up-lg" />
			</template>
			<template #key2>
				<u>{{ t("Switching Account") }}</u>
			</template>
		</i18n-t>
		<NuxtIcon name="16/arrow-right" filled />
	</AText>
</template>
<style lang="scss" scoped>
.topline {
	width: 100%;
	min-height: 32px;
	padding: gutter(1) gutter(2);
	gap: gutter(1);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--cusco);
	z-index: 2;
	line-height: 20px;
	cursor: pointer;

	.nuxt-icon {
		font-size: 20px;
	}

	@include media-breakpoint-up(sm) {
		.hide-up-lg {
			display: none;
		}
	}

	@include media-breakpoint-down(sm) {
		justify-content: flex-start;
		height: 52px;
		padding: 0 gutter(1.5);
	}
}
</style>
